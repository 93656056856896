/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { RemixBrowser } from '@remix-run/react';

if (ENV.SENTRY_DSN) {
	const { init } = await import('./utils/monitoring.client.ts');
	init();
}

async function prepareApp() {
	if (ENV.MOCK) {
		const [{ getMockUmdBundleHandlers, getServerHandlers }, { setupWorker }] =
			await Promise.all([import('mocks/handlers'), import('msw/browser')]);
		const worker = setupWorker(
			...getMockUmdBundleHandlers(ENV.MOCK),
			...getServerHandlers(ENV.MOCK),
		);
		return worker.start({ onUnhandledRequest: 'bypass' });
	}

	return Promise.resolve();
}

prepareApp().then(() => {
	startTransition(() => {
		hydrateRoot(
			document,
			<StrictMode>
				<RemixBrowser />
			</StrictMode>,
		);
	});
});
